import React, { Component } from 'react'
import { ConstantsNames } from "../../Urls"
import FormConnector from '../../forms/FormConnector';
import TextToHtml from '../../Texthtml';
import Container from '@material-ui/core/Container';
import { NavLink } from 'react-router-dom'; 

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */


import b_facebookIcon from './../../../img/icons/black/facebook.svg';
import b_instagram from './../../../img/icons/black/instagram.svg';
import b_youtube from './../../../img/icons/black/youtube.svg';
//import b_whatsapp from './../../../img/icons/black/whatsapp.svg';
import logo from './../../../img/default/logoMenu.svg';

import dans1 from './../../../img/icons/dans1.jpg';
import city2023 from './../../../img/icons/logo2023.png';
import bdi from './../../../img/icons/2023_2.png';


export default class Footer extends Component {

    
    

    render() {

      
        let site = this.props.info.SiteDataitems;
        let Background = ConstantsNames.pic + site.settings.footer_bg;
        //console.log(this.props);

        let ProjetsLow = this.props.info.SiteDataitems.ProjetsLow;

        let projectsShivuk =  ProjetsLow.filter(function(desc) {
            return desc.data.statusProj === 'פרויקטים בשיווק';
        });

        let projectsAtid =  ProjetsLow.filter(function(desc) {
            return desc.data.statusProj === 'פרויקטים עתידים';
        });

        let projectsUclas =  ProjetsLow.filter(function(desc) {
            return desc.data.statusProj === 'פרויקטים שאוכלסו';
        });


        let PagesNames = {

            home: [{
              url:'/' + this.props.info.SiteDataitems.pages[0].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[0].title
            }],
      
            contact: [{
              url:'/' + this.props.info.SiteDataitems.pages[3].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[3].title
            }],
      
            projects: [{
              url:'/' + this.props.info.SiteDataitems.pages[5].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[5].title
            }],
      
            mishtaken: [{
              url:'/פרויקטים/משתכן',
            }],
      
            center: [{
              url:'/' + this.props.info.SiteDataitems.pages[7].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[7].title
            }],
      
            articles: [{
              url:'/' + this.props.info.SiteDataitems.pages[8].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[8].title
            }],
      
            about: [{
              url:'/' + this.props.info.SiteDataitems.pages[9].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[9].title
            }],
      
            jobs: [{
              url:'/' + this.props.info.SiteDataitems.pages[10].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[10].title
            }],
      
            calc: [{
              url:'/' + this.props.info.SiteDataitems.pages[11].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[11].title
            }],

            newCity: [{
                url:'/' + this.props.info.SiteDataitems.pages[13].seo.friendly,
                namePage : this.props.info.SiteDataitems.pages[13].title
              }],

            accessibility: [{
                url:'/' + this.props.info.SiteDataitems.pages[2].seo.friendly,
                namePage : this.props.info.SiteDataitems.pages[2].title
            }]
      
          }

        //console.log(this.props.info.SiteDataitems.pages);
              
        return (


            <div className="footer-wrapper">
                <footer className="site-footer" role="contentinfo">

                    <section aria-label={site.settings.form_title} className='formSec' style={{backgroundImage: "url(" + Background + ")" }} >
                        <TextToHtml text={this.props.page === 'welcome/center' ? site.settings.center_form_titleProjects : site.settings.form_title} format="h2"/>                        
                        <h3 className='formMustText'>{site.settings.formMustText}</h3>
                        <FormConnector aria-label={site.settings.form_title} pageData={this.props} infoSite={this.props.info} showProjects={this.props.page === 'welcome/center' ? false : true} center={this.props.page === 'welcome/center' ? true:false }/>
                    </section>
                    

                    <section className=''>
                        <Container maxWidth="lg" >
                            <div className='siteMapTable'>
                                <div className='col sites'>
                                    {/* <h2>הפרוייקטים שלנו</h2> */}
                                    <div className="tableProjects">

                                        <div className="col">
                                            <h2>דפי האתר</h2>
                                            <ul role="menu" aria-label='דפי האתר'>
                                                
                                                <li role="menuitem" >
                                                    <NavLink className="home" activeClassName='is-active' to="/" onClick={() => scroll.scrollTo(0)}>
                                                        {PagesNames.home[0].namePage}
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" onClick={() => scroll.scrollTo(0)} to={PagesNames.projects[0].url} > פרויקטים מגורים</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" onClick={() => scroll.scrollTo(0)} to={PagesNames.mishtaken[0].url} >פרויקטים למשתכן</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.newCity[0].url}>{PagesNames.newCity[0].namePage}</NavLink>
                                                </li>
                                                
                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.center[0].url}>מסחרי</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.articles[0].url}>{PagesNames.articles[0].namePage}</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.about[0].url}>{PagesNames.about[0].namePage}</NavLink>
                                                </li>

                                                

                                                

                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.jobs[0].url}>{PagesNames.jobs[0].namePage}</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.contact[0].url}>{PagesNames.contact[0].namePage}</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.calc[0].url}>{PagesNames.calc[0].namePage}</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.accessibility[0].url}>{PagesNames.accessibility[0].namePage}</NavLink>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="col">
                                            <h2>פרויקטים בשיווק</h2>
                                            <ul role="menu" aria-label='הפרוייקטים שלנו'>
                                                {projectsShivuk.map(item => (
                                                <li role="menuitem" key={'footer' + item.data.id} >
                                                    <NavLink onClick={() => scroll.scrollTo(0)} activeClassName='is-active' to={"/פרויקטים/" + item.seo.friendly }>{item.data.name} - ב{item.data.city}</NavLink>
                                                </li>
                                                )
                                                )}
                                            </ul>
                                        </div>

                                        <div className="col">
                                            <h2>פרויקטים עתידיים</h2>
                                            <ul role="menu" aria-label='הפרוייקטים שלנו'>
                                                {projectsAtid.map(item => (
                                                <li role="menuitem" key={'footer' + item.data.id} >
                                                    <NavLink onClick={() => scroll.scrollTo(0)} activeClassName='is-active' to={"/פרויקטים/" + item.seo.friendly }>{item.data.name} - ב{item.data.city}</NavLink>
                                                </li>
                                                )
                                                )}
                                            </ul>
                                        </div>

                                        <div className="col">
                                            <h2>פרויקטים שאוכלסו</h2>
                                            <ul role="menu" aria-label='הפרוייקטים שלנו'>
                                                {projectsUclas.map(item => (
                                                <li role="menuitem" key={'footer' + item.data.id} >
                                                    <NavLink onClick={() => scroll.scrollTo(0)} activeClassName='is-active' to={"/פרויקטים/" + item.seo.friendly }>{item.data.name} - ב{item.data.city}</NavLink>
                                                </li>
                                                )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='col social'>
                                     
                                    <img className='mainLogo' src={logo} alt={this.props.info.SiteDataitems.settings.sitename} />
                                    
                                    {/* <a className='whatsapp' href={'https://api.whatsapp.com/send?phone=' + this.props.info.SiteDataitems.settings.whatsapp} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_whatsapp} alt='whatsapp' />
                                    </a> */}

                                    <a href={this.props.info.SiteDataitems.settings.instagram} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_instagram} alt='instagram' />
                                    </a>

                                    <a href={this.props.info.SiteDataitems.settings.facebook} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_facebookIcon} alt='facebook' />
                                    </a>

                                    <a href={this.props.info.SiteDataitems.settings.youtube} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_youtube} alt='youtube' />
                                    </a>

                                    <div className="dansLogos">
                                        <img src={dans1} alt="חותם אמינות - DUN & BRADSTREET" />
                                        <img src={city2023} alt="מדד ההתחדשות העירונית 2023" />
                                    </div>

                                    <div className="bdiLogo">
                                        <img src={bdi} alt="BDI CODE" />
                                    </div>
                                    
                                </div>
                            </div>
                        </Container>
                    </section>

                    <div className="credit">
                        <a href="http://ak-adv.co.il" target="_blank" rel="noopener noreferrer">
                            <img src="//ak-digital.net/ak-mobile/mail_img/ak-b.gif" alt="Avioz-Krispin" />
                        </a>
                        <p className='avisrorTxt'>אביסרור משה ובניו עבודות בניין ופיתוח בע”מ</p>
                    </div>

                </footer>
            </div>
        )
    }
}
